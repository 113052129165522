export type Tag = { name: string; selected: boolean };

// TODO
// Rename to useFilterStore

export const useTagStore = () => {
  const router = useRouter();
  const route = useRoute();

  const tags = useState<Set<Tag>>("tags", () => new Set<Tag>());

  const setTags = (value: string[]) => {
    if (!value || value.length === 0) {
      tags.value = new Set<Tag>();
      return;
    }

    const query = route.query;
    const selectedTags = decodeURIComponent(query.tags as string).split(",");

    tags.value = new Set<Tag>(
      value.map((tag) => ({ name: tag, selected: selectedTags.includes(tag) }))
    );
  };

  const removeAllTags = () => {
    tags.value = new Set<Tag>();
  };

  const toggleTag = (tag: string, path?: string) => {
    tags.value.forEach((t) => {
      if (t.name === tag) {
        t.selected = !t.selected;
      }
    });
    // TODO
    // replace with navigateTo
    router.replace({
      path: path || route.path,
      query: {
        ...route.query,
        ...{
          tags: encodeURI(
            [...tags.value]
              .filter((t) => t.selected)
              .map((t) => t.name)
              .toString()
          ),
        },
      },
    });
  };

  const selectedTags = computed(() => {
    return [...tags.value].filter((t) => t.selected);
  });

  return {
    setTags,
    removeAllTags,
    toggleTag,
    tags,
    selectedTags,
  };
};
